.opendash-reporting-list {
  margin: 0 auto;
  padding: $gutter;
  max-width: 855px;

  background: $white;
  color: $white-font;

  position: relative;

  @include raised();
}

.opendash-reporting-list .opendash-reporting-filterbar {
  box-sizing: border-box;
  display: inline;
  float: right;
}
.opendash-reporting-list .opendash-reporting-filterbar input {
  margin-left: 10px;
  width: 400px;
}
.opendash-reporting-list h3 {
  display: inline;
}
.opendash-reporting-list .opendash-reporting-list-oldreports {
  border-top: 1px solid $primary;
  padding-top: 30px;
  box-sizing: border-box;
  width: 100%;

  @extend .clearfix;
}
.opendash-reporting-list .opendash-reporting-marketlist {
  width: 100%;
  display: inline-block;
}

.opendash-reporting-newButton-holder {
  margin-top: 10px;
  min-height: 60px;
  width: 100%;
  text-align: right;
  button {
    margin: 0px;
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.3s;
  }
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.report-new-holder {
  box-sizing: border-box;
  margin-top: 10px;
  background-color: $primary;
  transition: box-shadow 0.3s ease-in-out;
  text-align: center;
  width: 150px;
  height: 50px;
  color: #fff;
  i {
    color: #fff;
    display: inline-block;
  }
  h6 {
    color: #fff;
    display: inline-block;
  }
}

.opendash-reporting-list .report-item-holder {
  box-sizing: border-box;
  width: 250px;
  height: 250px;
  float: left;
  padding-top: 30px;
  padding-left: 20px;
  position: relative;
  background: #fff;
  border: 1px solid #ccc;
  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    border-bottom: 5px solid $primary;
  }
  margin: 10px;
  .opendash-reporting-typeicon {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .opendash-reporting-deleteIcon {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
  b {
    width: 165px;
  }
  .report-item-description {
    position: absolute;
    top: 80px;
    left: 20px;
    right: 20px;
  }
  .opendash-reporting-itemdescription {
    .description-title {
      font-style: oblique;
      color: #444;
    }
    font-size: 0.8em;
    right: 20px;
    color: #666;
    text-align: justify;
  }
  .opendash-reporting-redownload {
    position: absolute;
    left: 20px;
    bottom: 20px;
  }
}

.opendash-reporting-list ul li * {
  display: inline-block;
}
.opendash-reporting-list ul {
  list-style-type: none;
  background-color: #fff;
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;
  border: 1px solid #bbb;
}
@media only screen and (max-width: 768px) {
  .hide-on-mobile {
    display: none !important;
  }
}
