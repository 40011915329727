od-header {
  font-family: "Raleway", sans-serif;
  user-select: none;
  .od-header__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.75);
    z-index: 5000;
    display: none;
    &.active {
      display: block;
    }
  }
}
