.opendash-widget-energy-peaktablemeta {
  height: 50px;
  text-align: center;
}
.opendash-widget-energy-peaktable {
  background: white;
  padding: 10px;

  margin-top: 40px;
  height: calc(100% - 90px);
  overflow: auto;

  &__row {
    &:first-child {
      position: absolute;
      width: 98%;
      height: 40px;

      margin-top: -50px;
      font-weight: bold;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      // margin-bottom: 10px;
      padding: 5px 10px;
    }

    &:nth-child(2n) {
      background: rgba(0, 0, 0, 0.03);
    }
    padding: 2px 10px;
    display: flex;
    flex-direction: row;
  }

  &__col {
    width: 100%;
    line-height: 30px;
    overflow: hidden;
    text-align: center;

    i {
      color: rgba(0, 0, 0, 0.5);
      margin-left: 5px;
    }
  }
}
