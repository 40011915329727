od-notification {
  display: block;
  position: fixed;
  top: 60px;
  right: $gutter;
  z-index: 6000;
  ul,
  li {
    list-style: none;
    display: block;
  }
  ul {
    margin: 0;
    padding: 0;
  }
  li {
    padding: 10px;
    margin-top: $gutter;
    border-radius: 4px;
    font-size: 0.8em;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: all 0.5s ease-in-out;

    background: map-get(map-get($colors, "info"), "background");
    color: map-get(map-get($colors, "info"), "font");
    border-color: map-get(map-get($colors, "info"), "background-hover");

    @each $name, $color in $colors {
      &.notification--#{$name} {
        background: map-get($color, "background");
        color: map-get($color, "font");
        border-color: map-get($color, "background-hover");
      }
    }
  }
}
