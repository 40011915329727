hero {
  .od-loader__cube_one {
    background-color: #0063ac !important;
  }
  .od-loader__cube_two {
    background-color: #0063ac !important;
  }

  position: relative;

  .od-select-location__empty {
    color: white;
  }

  od-loading {
    //background: linear-gradient(0deg, #2974b3, #318fe4);
    z-index: 999;
  }

  .block-100-100 {
    display: block;
    height: 100%;
    width: 100%;
  }

  .ow__hero {
    display: block;
    position: relative;
    margin-top: 60px;
    margin-bottom: -60px;
    background-color: #ffffff;
    border-bottom: 2px solid $primary;
    width: 100%;
    padding: $gutter;

    @include media(">1200px") {
      height: 400px;
    }

    &__wrapper {
      max-width: 600px;
      margin: 0 auto;

      @include media(">1200px") {
        max-width: auto;
      }
    }

    &__current {
      display: none;
      color: white;
      font-size: 24px;
      text-align: left;

      table {
        width: 100%;
      }

      @include media(">1200px") {
        display: block;
        position: absolute;
        top: 130px;
        left: 90px;
      }
    }
    &__search {
      background-color: white;

      @include media(">1200px") {
        height: 40px;
        position: absolute;
        width: 500px;
        z-index: 999;
        top: 30px;
        right: 7%;
      }
    }
    &__map {
      display: none;
      @include media(">1200px") {
        display: block;
        height: 398px;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 1px;
      }
    }
    &__list {
      input {
        background: white;
      }

      &__filter {
        color: white;
        text-align: center;
        margin-top: 20px;
      }

      @include media(">1200px") {
        max-height: 240px;
        position: absolute;
        width: 600px; //NEW was 500px
        font-size:0.95em; //NEW
        z-index: 999;
        top: 80px;
        right: 5%; //NEW was 5%
      }

      &__container {
        list-style-type: none;
        padding: 0;
        margin: 0;
        text-align: left;
        max-height: 220px;
        overflow-y: auto;

        @include media(">1200px") {
          max-height: 260px;
        }
      }

      &__item {
        padding-left: 15px;
        border-bottom: 1px solid darkgray;
        padding: 0px;
        width: 100%;
        line-height: 40px;
        height: 40px;
        vertical-align: middle;
      }
    }
  }
}

@media only screen and (max-width: 550px) {
  hero {
    background-color: #ffffff;
    background: linear-gradient(0deg, #2974b3, #318fe4);
    width: 100%;
    height: 250px;
  }
}
