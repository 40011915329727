od-select-step {
  display: block;

  $steps: 4;
  $gutter: 5px;
  $modal-padding: 20px;
  $active-color: $primary;
  $eud-selection-height: 60px;
  $eud-nav-item-count: 4;
  $eud-nav-border: 4px;
  $eud-nav-div: 80px;
  $eud-nav-height: 60px;
  $eud-nav-inline-gap: 4px;
  $eud-nav-grey: #d6d6d6;
  $eud-nav-active: $active-color;
  $eud-nav-done: $primary;
  $eud-nav-after: darken($eud-nav-grey, 20%);
  $eud-footer-height: 60px;

  .od__select-steps {
    &__title {
      @extend .clearfix;
      padding: $gutter;
      h3 {
        padding-top: 5px;
      }
      &__left {
        width: 15%;
        text-align: left;
        float: left;
      }
      &__center {
        width: 70%;
        text-align: center;
        float: left;
      }
      &__right {
        width: 15%;
        text-align: right;
        float: left;
      }
    }

    &__main {
      padding: $gutter;
      padding-top: 5px;
      &__section {
        @extend .clearfix;
      }
    }
    &__footer {
      text-align: right;
      padding: $gutter;
    }
    &__icons {
      width: 100%;
      margin: $gutter 0;
      text-align: center;

      &__items {
      }

      &__item {
        display: inline-block;
        width: $eud-nav-height;
        height: $eud-nav-height;
        line-height: $eud-nav-height - 2 * $eud-nav-border;
        text-align: center;
        border-radius: 50%;
        border: $eud-nav-border solid;
        border-color: $eud-nav-grey;
        background: $od-color-background-light;
        font-weight: bold;
        margin-left: $eud-nav-div - $eud-nav-inline-gap;
        > span {
          font-size: 30px;
        }
        &:before {
          display: block;
          position: absolute;
          width: $eud-nav-div;
          height: $eud-nav-border;
          background-color: $eud-nav-grey;
          transform: translate(
            (($eud-nav-div + $eud-nav-border) * -1),
            (($eud-nav-height - 3 * $eud-nav-border)/2)
          );
          content: "";
        }
        &:after {
          width: $eud-nav-height + $eud-nav-div;
          display: block; // position: absolute;
          transform: translate(($eud-nav-div/2 + $eud-nav-border) * -1, 0px);
          content: attr(data-desc);
          color: $eud-nav-after;
          font-weight: normal;
          font-size: 0.9em;
        }
        &--active {
          border-color: $eud-nav-active;
          color: $eud-nav-active;
          &:before {
            background: $active-color;
            background-color: $primary; //background: linear-gradient(to right, $eud-nav-done 100%, $eud-nav-active 100%);
          }
          &:after {
            color: $eud-nav-active;
          }
        }
        &--done {
          background: $eud-nav-done;
          border-color: $eud-nav-done;
          color: white;
          &:before {
            background-color: $eud-nav-done;
            background-color: $primary;
          }
          &:after {
            color: $eud-nav-after;
          }
        }
        &:first-child {
          margin-left: 0;
          &:before {
            display: none;
          }
        }
      }
    }
  }
}
