od-select-date {
  display: block;
  padding: 0px;
  $active-color: $primary;
  .od-select-date {
    &--active {
      color: $active-color;
    }
    &__select {
      &-day,
      &-month,
      &-year {
        display: block;
        float: left;
        background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='50px' height='50px'><polyline points='46.139,15.518 25.166,36.49 4.193,15.519'/></svg>");
        background-repeat: no-repeat;
        background-position: right 10px top 15px;
        background-size: 16px 16px;
        width: auto;
        text-align: center;
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        border: 2px solid $active-color;
        -webkit-appearance: none;
        outline: 0;
        -webkit-transition: 0.3s ease all;
        -moz-transition: 0.3s ease all;
        -ms-transition: 0.3s ease all;
        -o-transition: 0.3s ease all;
        transition: 0.3s ease all;
      }
    }
    &__number-selection {
      width: 240px;
      margin: $gutter auto;
      &__minus {
        float: left;
        width: 60px;
        font-weight: bold;
      }
      &__plus {
        float: right;
        width: 60px;
        font-weight: bold;
      }
      &__number {
        display: block;
        width: 100px;
        margin-left: 70px;
        margin-right: 70px;
      }
    }
    &__selection {
      @extend .clearfix;
      text-align: center;
      color:#ddd;
      background: $od-color-background-light;
      border: 1px solid $od-color-border;
      &__item {
        float: left;
        width: 50%;
        padding: $gutter;
        &:hover {
          background: rgba(0, 0, 0, 0.02);
        }
        &:not(:last-child) {
          border-right: 1px solid $od-color-border;
        }
        &--3 {
          width: 33.333333%;
        }
        &--4 {
          width: 25%;
        }
        i {
          display: block;
          font-size: 4em;
          padding-bottom: $gutter/2;
          font-style: normal;
        }
      }
    }
    &__desc {
      color: rgba(0, 0, 0, 0.4);
      margin: 0;
      font-size: 0.9em;
    }
  }
}
