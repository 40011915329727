.btn-on {
	background: #00e608;
	background-image: -webkit-linear-gradient(top, #00e608, #0c8a15);
	background-image: -moz-linear-gradient(top, #00e608, #0c8a15);
	background-image: -ms-linear-gradient(top, #00e608, #0c8a15);
	background-image: -o-linear-gradient(top, #00e608, #0c8a15);
	background-image: linear-gradient(to bottom, #00e608, #0c8a15);
	-webkit-border-radius: 28;
	-moz-border-radius: 28;
	border-radius: 28px;
	font-family: Arial;
	color: #ffffff;
	font-size: 20px;
	padding: 10px 20px 10px 20px;
	text-decoration: none;
	height:40%;
}

.btn-on:hover {
	background: #185912;
	background-image: -webkit-linear-gradient(top, #185912, #083d05);
	background-image: -moz-linear-gradient(top, #185912, #083d05);
	background-image: -ms-linear-gradient(top, #185912, #083d05);
	background-image: -o-linear-gradient(top, #185912, #083d05);
	background-image: linear-gradient(to bottom, #185912, #083d05);
	text-decoration: none;
}
.btn-on-dark {
	background: #185912;
	background-image: -webkit-linear-gradient(top, #185912, #083d05);
	background-image: -moz-linear-gradient(top, #185912, #083d05);
	background-image: -ms-linear-gradient(top, #185912, #083d05);
	background-image: -o-linear-gradient(top, #185912, #083d05);
	background-image: linear-gradient(to bottom, #185912, #083d05);
	text-decoration: none;
	-webkit-border-radius: 28;
	-moz-border-radius: 28;
	border-radius: 28px;
	font-family: Arial;
	color: #ffffff;
	font-size: 20px;
	padding: 10px 20px 10px 20px;
	text-decoration: none;
}


.btn-off {
	background: #e60000;
	background-image: -webkit-linear-gradient(top, #e60000, #8a0c0c);
	background-image: -moz-linear-gradient(top, #e60000, #8a0c0c);
	background-image: -ms-linear-gradient(top, #e60000, #8a0c0c);
	background-image: -o-linear-gradient(top, #e60000, #8a0c0c);
	background-image: linear-gradient(to bottom, #e60000, #8a0c0c);
	-webkit-border-radius: 28;
	-moz-border-radius: 28;
	border-radius: 28px;
	font-family: Arial;
	color: #ffffff;
	font-size: 20px;
	padding: 10px 20px 10px 20px;
	text-decoration: none;
	height:40%;
}

.btn-off:hover {
	background: #571212;
	background-image: -webkit-linear-gradient(top, #571212, #3b0505);
	background-image: -moz-linear-gradient(top, #571212, #3b0505);
	background-image: -ms-linear-gradient(top, #571212, #3b0505);
	background-image: -o-linear-gradient(top, #571212, #3b0505);
	background-image: linear-gradient(to bottom, #571212, #3b0505);
	text-decoration: none;
}
.btn-off-dark {
	background: #571212;
	background-image: -webkit-linear-gradient(top, #571212, #3b0505);
	background-image: -moz-linear-gradient(top, #571212, #3b0505);
	background-image: -ms-linear-gradient(top, #571212, #3b0505);
	background-image: -o-linear-gradient(top, #571212, #3b0505);
	background-image: linear-gradient(to bottom, #571212, #3b0505);
	text-decoration: none;
	-webkit-border-radius: 28;
	-moz-border-radius: 28;
	border-radius: 28px;
	font-family: Arial;
	color: #ffffff;
	font-size: 20px;
	padding: 10px 20px 10px 20px;
	text-decoration: none;
}