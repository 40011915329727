$od-topbar-height: 60px !default;
$od-topbar-background: $od-color-background-light !default;

od-topbar {
  display: block;

  height: $od-topbar-height;
  width: 100%;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  background: $od-topbar-background;
  border-bottom: 2px solid $primary;

  z-index: 5050;

  &-container {
    display: block;
    height: $od-topbar-height;
  }

  &-logo {
    display: block;
    height: $od-topbar-height;
    float: left;
    img {
      display: none;
      @include media(">sm") {
        display: block;
        float: left;
        height: $od-topbar-height;
        width: auto;
        padding: $gutter/2 0;
      }
    }
    span {
        float: right;
        display: block;
        line-height: 60px;
        margin-left: 10px;
        font-size: 1.2em;
    }
  }

  .od-topbar {
    &__user {
      display: block;
      float: right;

      height: $od-topbar-height;

      position: relative;

      &:hover {
        // background: $primary;

        .od-topbar__user__icon i {
          color: $primary;
        }
      }

      &__icon {
        display: block;
        height: $od-topbar-height;
        width: $od-topbar-height;

        i {
          line-height: $od-topbar-height;
          height: $od-topbar-height;
          width: $od-topbar-height;
          color: $white-font;
          text-align: center;
          font-size: 30px;
        }

        img {
          border-radius: 50%;
          padding: $gutter/2;
        }

        &:hover {
          i {
            // color: white;
          }
        }
      }

      &__wellcome {
        padding: 10px;
        color: $primary-font;

        &__message {
          font-size: 0.8em;
        }
      }

      &__actions {
        display: block;

        position: absolute;
        top: $od-topbar-height - 8px;
        right: 0;

        padding: 1px;
        background: $primary-hover;

        $arrow-size: 5px;

        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);

        &:before {
          width: 0;
          height: 0;
          border-left: $arrow-size solid transparent;
          border-right: $arrow-size solid transparent;

          border-bottom: $arrow-size solid $primary-hover;
          content: "";
          position: absolute;
          top: -$arrow-size;
          right: $od-topbar-height/2-$arrow-size;
        }

        a {
          color: $primary-font;
          background: $primary;

          display: block;
          line-height: 40px;

          min-width: 300px;

          padding: 0 $gutter;

          border-bottom: 1px solid $primary-hover;

          &:first-of-type {
            border-top: 1px solid $primary-hover;
          }

          &:hover {
            color: $primary-hover-font;
            background: $primary-hover;
          }
        }
      }
    }
  }

  &-navigation {
    display: block;
    float: right;

    > a {
      display: block;
      float: left;

      padding: 0 $gutter/2;

      color: $white-font;

      height: $od-topbar-height;
      line-height: $od-topbar-height;

      > i.fa {
        font-size: 20px;
        line-height: $od-topbar-height;
        margin-right: 5px;
      }

      > span {
        display: none;
        float: right;
        line-height: $od-topbar-height;
        height: $od-topbar-height;

        @include media(">800px") {
          display: block;
        }
      }

      &:hover {
        color: $primary-font;
        background: $primary;

        > nav {
          display: block;
        }
      }
    }

    section {
      display: block;
      position: relative;
      float: left;

      padding: 0 $gutter/2;

      height: $od-topbar-height;
      line-height: $od-topbar-height;

      nav {
        display: none;

        position: absolute;
        top: $od-topbar-height;
        right: 0;

        width: auto;
        min-width: 300px;

        background: $primary;

        border-bottom: 2px solid $primary-hover;

        a {
          display: block;
          color: $primary-font;
          padding: 0 $gutter/2;

          > i.fa {
            width: $od-topbar-height - $gutter;
            text-align: center;
          }

          &:hover {
            color: $primary-hover-font;
            background: $primary-hover;
          }
        }
      }

      &:hover {
        color: $primary-font;
        background: $primary;

        nav {
          display: block;
        }
      }
    }
  }

  // > nav {

  //     > a {
  //         height: $od-topbar-height;
  //         line-height: $od-topbar-height - 20px;
  //         color: #555;
  //         float: left;
  //         padding: 10px 20px;
  //         display: block;
  //         text-decoration: none;
  //         &.right {
  //             float: right;
  //         }
  //         &:hover {
  //             background: rgba(0, 0, 0, .05);
  //         }
  //     }
  // }
  &-hamburger {
    display: block;
    width: $od-topbar-height;
    height: $od-topbar-height;
    padding: $gutter/2;

    float: left;

    .hamburger {
      $hamburger-height: $od-topbar-height - $gutter;
      $hamburger-width: $od-topbar-height - $gutter;
      $hamburger-line-height: 2px;
      $hamburger-line-offset: 8px;
      $hamburger-line-color: $white-font;
      width: $hamburger-width;
      height: $hamburger-height;
      position: relative;
      margin: (
          $hamburger-height - $hamburger-line-offset * 2 -
            $hamburger-line-height
        )/2 0;
      transform: rotate(0deg);
      transition: 0.5s ease-in-out;
      cursor: pointer;
      span {
        display: block;
        position: absolute;
        height: $hamburger-line-height;
        width: 100%;
        background: $hamburger-line-color; // border-radius: 50%;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;
        &:nth-child(1) {
          top: 0px;
        }
        &:nth-child(2),
        &:nth-child(3) {
          top: $hamburger-line-offset;
        }
        &:nth-child(4) {
          top: $hamburger-line-offset * 2;
        }
      }
      &.open {
        span {
          &:nth-child(1),
          &:nth-child(4) {
            top: 18px;
            width: 0%;
            left: 50%;
          }
          &:nth-child(2) {
            transform: rotate(45deg);
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
