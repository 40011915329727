.btn-on {
  background: #00e608;
  background-image: -webkit-linear-gradient(top, #00e608, #0c8a15);
  background-image: -moz-linear-gradient(top, #00e608, #0c8a15);
  background-image: -ms-linear-gradient(top, #00e608, #0c8a15);
  background-image: -o-linear-gradient(top, #00e608, #0c8a15);
  background-image: linear-gradient(to bottom, #00e608, #0c8a15);
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  font-family: Arial;
  color: #ffffff;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  height: 40%;
}

.btn-on:hover {
  background: #185912;
  background-image: -webkit-linear-gradient(top, #185912, #083d05);
  background-image: -moz-linear-gradient(top, #185912, #083d05);
  background-image: -ms-linear-gradient(top, #185912, #083d05);
  background-image: -o-linear-gradient(top, #185912, #083d05);
  background-image: linear-gradient(to bottom, #185912, #083d05);
  text-decoration: none;
}
.btn-on-dark {
  background: #185912;
  background-image: -webkit-linear-gradient(top, #185912, #083d05);
  background-image: -moz-linear-gradient(top, #185912, #083d05);
  background-image: -ms-linear-gradient(top, #185912, #083d05);
  background-image: -o-linear-gradient(top, #185912, #083d05);
  background-image: linear-gradient(to bottom, #185912, #083d05);
  text-decoration: none;
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  font-family: Arial;
  color: #ffffff;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
}

.btn-off {
  background: #e60000;
  background-image: -webkit-linear-gradient(top, #e60000, #8a0c0c);
  background-image: -moz-linear-gradient(top, #e60000, #8a0c0c);
  background-image: -ms-linear-gradient(top, #e60000, #8a0c0c);
  background-image: -o-linear-gradient(top, #e60000, #8a0c0c);
  background-image: linear-gradient(to bottom, #e60000, #8a0c0c);
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  font-family: Arial;
  color: #ffffff;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
  height: 40%;
}

.btn-off:hover {
  background: #571212;
  background-image: -webkit-linear-gradient(top, #571212, #3b0505);
  background-image: -moz-linear-gradient(top, #571212, #3b0505);
  background-image: -ms-linear-gradient(top, #571212, #3b0505);
  background-image: -o-linear-gradient(top, #571212, #3b0505);
  background-image: linear-gradient(to bottom, #571212, #3b0505);
  text-decoration: none;
}
.btn-off-dark {
  background: #571212;
  background-image: -webkit-linear-gradient(top, #571212, #3b0505);
  background-image: -moz-linear-gradient(top, #571212, #3b0505);
  background-image: -ms-linear-gradient(top, #571212, #3b0505);
  background-image: -o-linear-gradient(top, #571212, #3b0505);
  background-image: linear-gradient(to bottom, #571212, #3b0505);
  text-decoration: none;
  -webkit-border-radius: 28;
  -moz-border-radius: 28;
  border-radius: 28px;
  font-family: Arial;
  color: #ffffff;
  font-size: 20px;
  padding: 10px 20px 10px 20px;
  text-decoration: none;
}

.opendash-widget-electricity-overview {
  #widget-content-header {
    height: 20%;
  }
  #widget-content-header-image {
    float: left;
    width: 35%;
    height: 100%;
  }
  #widget-content-header-image-img {
    max-height: 100%;
    padding: 5%;
  }
  #widget-content-header-text {
    float: left;
    width: 65%;
    height: 100%;
  }
  #widget-content-header-text-border {
    width: 100%;
    height: 100%;
    margin: auto;
  }
  #widget-content-content {
    height: 80%;
  }
  #widget-content-content-chart {
    float: left;
    width: 90%;
    height: 100%;
  }
}

/* 2 Widget */
.od-widget--height--2 {
  .opendash-widget-electricity-overview {
    #widget-content-content {
      display: none;
    }
    #widget-content-header {
      height: 100%;
    }
    #widget-content-header-image {
      display: flex;
    }
    #widget-content-header-image-img {
      margin: auto;
    }
    #widget-content-header-text {
      display: flex;
    }
    #widget-content-header-text-border {
      height: 50%;
    }
  }
}

/* 3x3 Widget */
.od-widget--width--3.od-widget--height--3 {
  .opendash-widget-electricity-overview {
    #widget-content-content-chart {
      width: 90%;
    }
    #widget-content-content-trend {
      display: none;
    }
  }
}

/* 4x4 Widget */
.od-widget--width--4.od-widget--height--4 {
  .opendash-widget-electricity-overview {
    #widget-content-content-chart {
      width: 90%;
    }
  }
}

/* 2x2 Widget */
.od-widget--width--2.od-widget--height--2 {
  .opendash-widget-electricity-overview {
    #widget-content-header-image {
      display: none;
    }
    #widget-content-header-text {
      width: 100%;
    }
    #widget-content-content {
      display: none;
    }
    #widget-content-header {
      height: 100%;
    }
    #widget-content-header-image-img {
      margin: auto;
    }
    #widget-content-header-text {
      display: flex;
    }
    #widget-content-header-text-border {
      height: 50%;
    }
  }
}

/* 2x3 Widget */
.od-widget--width--3.od-widget--height--2 {
  .opendash-widget-electricity-overview {
    #widget-content-content {
      display: none;
    }
    #widget-content-header {
      height: 100%;
    }
    #widget-content-header-image {
      display: flex;
    }
    #widget-content-header-image-img {
      margin: auto;
    }
    #widget-content-header-text {
      display: flex;
    }
    #widget-content-header-text-border {
      height: 50%;
    }
  }
}

.chartlist {
  padding: 0px;
  height: 100%;
  overflow: auto;
  text-align: left;
  color: #000;
}

.chartlist ul {
  height: 100%;
  overflow: auto;
}

.chartlist li {
  position: relative;
  display: block;
}

.chartlist .count {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 0.3em;
  text-align: right;
  color: #999;
  font-weight: bold;
  font-size: 0.875em;
}

.chartlist li a {
  display: block;
  padding: 0.4em 4.5em 0.4em 0.5em;
  position: relative;
  z-index: 2;
}

.chartlist .index {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  text-indent: -9999px;
  overflow: hidden;
}
