.od-auth {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3200;
  background: $light;
}

.container--auth {
  padding: $gutter;
  margin-top: 100px;
  background: $white;
  border: 1px solid $od-color-border;
  max-width: 500px;
  @include media(">500px") {
    border-radius: 5px;
  }
}
