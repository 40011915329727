// @import url("https://fonts.googleapis.com/css?family=Raleway|Roboto:400,400i,700,700i");

@import "vars";
@import "font";

@import url("https://ajax.googleapis.com/ajax/libs/angular_material/1.2.1/angular-material.min.css");
@import "node_modules/opendash/style/base";
@import "node_modules/opendash-plugin-openware/style/base";
@import "components/hero";
// @import "components/sidebar";
@import "components/vsensor";

*:focus{
    outline: none;
}
.gridster-mobile .gridster-item {
  margin: 0px !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
}

.leaflet-container {
  background: rgba(0, 0, 0, 0) !important;
}

.openAnalytics {
  $tile-color: rgb(213, 217, 46);
  background: $tile-color;
  border-color: $tile-color;
  &:hover {
    background: white;
    color: $tile-color;
  }
}
.openreport {
  $tile-color: rgb(127, 187, 71);
  background: $tile-color;
  border-color: $tile-color;
  &:hover {
    background: white;
    color: $tile-color;
  }
}
.openfeedback {
  $tile-color: rgb(127, 187, 71);
  background: $tile-color;
  border-color: $tile-color;
  &:hover {
    background: white;
    color: $tile-color;
  }
}

.ow-frontpage div.opendash {
  $tile-color: $primary;
  background: $tile-color;
  border-color: $tile-color;
  &:hover {
    background: white;
    color: $tile-color;
  }
}

body {
    height: initial!important;
}
/*
od-widget-header {
    height: 36px !important;
    background: #f7f5f5 !important;
    border-bottom: 0px solid #CDCDCD !important;
}

od-widget-header-action {
    cursor: pointer !important;
    border-left: 0px solid #cdcdcd !important;
    display: block !important;
    float: right !important;
    height: 36px !important;
    width: 36px !important;
    color: #999 !important;
    padding-top:3px !important;
}

od-widget-header-name {
    margin: 0 !important;
    font-size: 14px !important;
    color: #ff6600 !important;
    font-weight: bold !important;
    padding-left: 15px !important;
    padding-top: 5px !important;
    -webkit-transition: all 0.2s ease-in-out !important;
    -moz-transition: all 0.2s ease-in-out !important;
    transition: all 0.2s ease-in-out !important;
    float: left !important;
}

od-widget {
    border: 0px solid #CACACA !important;
    border-top: 2px solid #CACACA !important;
    border-bottom: 2px solid #CACACA !important;
    background:#f4f3f3 !important;
}

od-header od-topbar {
    border-bottom: 2px solid #ff6600 !important;
}

.od-sidebar, od-sidebar {
    color: black !important;
    background:#ffffff !important;
}



.od-sidebar a, od-sidebar a {
    color: #969696 !important;
}



.od-sidebar section header, od-sidebar section header {
    color: #ff6600 !important;
}

.button, button, input[type=button], input[type=reset], input[type=submit] {
    background-color: #ff6600 !important;
    border: .1rem solid #ff6600 !important;;
}

od-eud-editor .eud__nav__item--active {
    color: #ff6600 !important;
}

od-eud-editor .eud__nav__item--active {
    color: #ff6600 !important;
    border-color: #ff6600 !important;
}

od-eud-editor .eud__nav__item--done {
    background: #ff6600 !important;
    border-color: #ff6600 !important;
}

od-eud-editor div .eud__nav__item--done:before {
    background-color: #ff6600 !important;
}

od-eud-editor div .eud__nav__item--active:before {
    background-color: #ff6600 !important;
}

od-tabs>nav>a.active {
    border-bottom: 3px solid #ff6600 !important;
}

od-select-date .od-select-date--active {
    color: #ff6600 !important;
}

od-select-date .od-select-date__select-day, od-select-date .od-select-date__select-month, od-select-date .od-select-date__select-year {
    border: 2px solid #ff6600 !important;
}

od-eud-editor .eud--active {
    color: #ff6600 !important;
}

.gridster .gridster-item .handle-se {
    border-color: transparent transparent #ff6600 !important;
}

.handle-se {
right: 0px !important;
bottom: 0px !important;
border-width: 0 0 17px 17px !important;
}

od-loading {
    background: #f7f5f5 !important;
}

section.eud__main__section div.od_dashboard_widget {
    background: #fff;
}

body {
    background: #FFFFFF !important;
    color: #636363 !important;
}

od-header od-topbar {
    
    top: auto !important;
    bottom:0 !important;
    border-bottom: 0px solid #ff6600 !important;
    border-top: 2px solid #ff6600 !important;
    
    display:absolute !important;
    border-bottom: 0px solid #ff6600 !important;
}

opendash {
    padding:auto;
    padding-top:60px !important;

    padding-top:20px !important;
    padding-bottom:80px !important;

}

od-header od-topbar nav img {
    display:none !important;
}

od-loading .od-loader__cube_one, od-loading .od-loader__cube_two {
    background-color: #ff6600 !important;
}
*/
