.od-preset {
  margin-top: $gutter; // padding: $gutter/2;
  // border-bottom: 1px solid rgba(0,0,0,.2);
  @extend .clearfix;

  img {
    float: left;
    width:  99%;
    height: 127px;
    object-fit: scale-down;
}

  &__group_1,
  &__group_2 {
    padding: $gutter/2;
  }
  &__group_1 {
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: $white;
    margin: 0 auto;
    width: 300px;
  }
  &__group_2 {
    display: none;
  }
  &__image {
    width: 100%;
  }
  &__button {
    width: 100%;
    margin-bottom: 0;
  }
  &__name {
    font-weight: bold;
  }
  @include media(">550px") {
    &__group_1 {
      width: 45%;
      margin: 0;
    }
    &__group_2 {
      display: block;
      width: 55%;
    }
    &__group_1,
    &__group_2 {
      float: left;
    }
  }
}
