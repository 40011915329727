@import "components/sidebar";
@import "components/frontpage";
@import "components/playground";
@import "components/reporting-create";
@import "components/reporting-list";
@import "components/admin_sensors";
@import "components/admin-access-cards";

/*
input.ng-valid {
    background-color:#3374cc;
    color:white;
}
*/



.openware-alarm od-tabs > nav > a {
  width:50%;
  text-align: center;
}





opendash-market-admin {
  button {
    margin: 2px;
  }
}

table.ng-table {
  font-family: "Roboto Light", sans-serif !important;
  border-collapse: collapse;
  line-height: 3;
  margin: $gutter;
  max-width: 1200px;
  border: 1px solid #e0e0e0;
  thead {
    input {
      border: none;
      color: $primary;
      &::placeholder {
        color: #e0e0e0;
        text-align: center;
      }
      &:focus {
        border-bottom: 1px solid $primary;
      }
    }
  }
  tbody {
    text-align: center;
    tr {
      margin-bottom: $gutter;
      border-bottom: 1px solid #e0e0e0;
      &:hover {
        border-top: 2px solid $primary;
        border-bottom: 2px solid $primary;
      }
    }
  }
  tr {
    th {
      color: $primary !important;
      background: none !important;
    }
  }
}

.ng-table .header {
  font-weight: 900;
  color: #ffffff;
  background: $primary;
}
.ng-table th.sortable.sort-asc,
.ng-table th.sortable.sort-desc {
  color: #ffffff;
  background: $primary;
  text-shadow: none;
}

.ng-table {
  .table {
    margin: 0 0 40px 0;
    width: 100%;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    display: table;
    @media screen and (max-width: 580px) {
      display: block;
    }
  }

  input {
    min-height: 20px;
  }

  .row {
    display: table-row;
    background: #f6f6f6;

    &:nth-of-type(odd) {
      background: #e9e9e9;
    }

    @media screen and (max-width: 580px) {
      padding: 14px 0 7px;
      display: block;

      &.header {
        padding: 0;
        height: 6px;

        .cell {
          display: none;
        }
      }

      .cell {
        margin-bottom: 10px;

        &:before {
          margin-bottom: 3px;
          content: attr(data-title);
          min-width: 98px;
          font-size: 10px;
          line-height: 10px;
          font-weight: bold;
          text-transform: uppercase;
          color: #969696;
          display: block;
        }
      }

      .cell {
        padding: 6px 12px;
        display: table-cell;
        @media screen and (max-width: 580px) {
          padding: 2px 16px;
          display: block;
        }
      }
    }
  }

  /*
.opendash-market-admin {
  .ng-table {
    text-align: center;
  }
  nav {
    background-color: #eee !important;
    border-top: 0px;
  }
}
.ng-table tr:not(:last-child) {
  border-right: 1px solid #999;
}

.ng-table th.sortable {
  border-right: 1px solid #999;
}

.ng-table th.filter {
  background-color: $primary;
  opacity: 0.4;
  color: white;
  border-right: 1px solid #999;
}



//AB hier Table Style neu
.opendash-market-admin table:not(:last-child),
.opendash-market-admin td:not(:last-child),
.opendash-market-admin tr:not(:last-child) {
  clear: both;
  background-color: #fff;
  border-spacing: 0px;
  border-right: 1px solid #999;
  border-left: 1px solid #999;
  border-collapse: collapse;
}

table:last-child,
th:last-child,
td:last-child, 
tr:last-child {
  border: 0px !important;
}

//Bis hier

.editable-table > tbody > tr > td {
  padding: 4px;
}
.editable-text {
  padding-left: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  display: inline-block;
}
.editable-table tbody > tr > td > .controls {
  //width: 100%
}
.editable-input {
  padding-left: 3px;
}
.editable-input.input-sm {
  height: 30px;
  font-size: 14px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.ng-table-counts.btn-group.pull-right.ng-scope button {
  margin: 2px;
}

.ng-table-pager.ng-scope {
  margin-top: 15px;
}
*/
}
