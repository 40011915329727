.opendash-widget-reference-timeline {
  /**
 Source: https://dev.to/peterc/how-to-create-joined-bulletpoint-lists-with-css-bbc-news-style-1eem
**/
h4{
  color: #0063ac ;
}
ul {
  list-style-type: none;
}

li {
  position: relative;
  margin: 0;
  padding-bottom: 1em;
  padding-left: 20px;
}

li:before {
  content: '';
  background-color:#0063ac ;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 6px;
  width: 3px;
}

li:after {
  content: '';
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' aria-hidden='true' viewBox='0 0 32 32' focusable='false'%3E%3Ccircle stroke='none' fill='%23c00' cx='16' cy='16' r='10'%3E%3C/circle%3E%3C/svg%3E");
  position: absolute;
  left: 0;
  height: 15px;
  width: 15px;
}
}
