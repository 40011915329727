opendash {
  display: block;
}

opendash {
  padding-top: 80px;
}

@media only screen and (max-width: 550px) {
  opendash {
    padding-top: 60px;
  }
}
