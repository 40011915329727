.openware-admin-access-cards {
  &__table {
    &__row {
      &:first-child {
        font-weight: bold;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);
        // margin-bottom: 10px;
        padding: 5px 10px;
      }

      &:nth-child(2n) {
        background: rgba(0, 0, 0, 0.03);
      }
      padding: 2px 10px;
      display: flex;
      flex-direction: row;
    }

    &__col {
      width: 100%;
      //line-height: 40px;
      overflow: hidden;

      // border: 1px solid red;

      position: relative;
      padding: 3px;

      input {
        border-bottom: none;
        background: rgba(0, 0, 0, 0.05);
        border-radius: 3px;
        margin: 0;
      }
    }

    &__marker {
      position: absolute;
      right: 12px;
      top: 15px;
    }
  }
}
