.ow_admin_sensors__modal_card {
  border: 1px solid #ccc;
  background: white;
  padding: $gutter;
  margin-bottom: $gutter;

  > b {
    display: block;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-bottom: $gutter;
  }
}
.sensorsettings {
  .od_user_settings__data__table__col__id {
    font-size: 0.8em;
    font-style: italic;
    color: #999;
    margin-bottom: $gutter;
  }
  .od_sensor_settings_sensoritem {
    border: 1px solid #ccc;
    margin-bottom: $gutter;
  }
  .od_user_settings__data__table__col__dimensions_item {
    font-size: 0.8em;
  }
  .od_user_settings__data__table__col__dimensions {
    font-style: bold;
    font-size: 0.8em;
  }
  .ow_admin_sensors__modal_checkbox {
    margin-right: $gutter;
  }
}
