ow-sidebar {
  .od-sidebar {
    left: auto;
    right: 0;
    max-height: 100%;
    height: 100vh;
    color: #333;

    max-width: 600px;

    transform: translateX(100%) !important;

    padding: 0;
    padding-top: 60px;

    .color-primary {
      color: $primary;
    }

    od-tabs > nav > a {
      text-align: center;
      font-size: 16px !important;
      margin-top: 10px !important;
      text-transform: none !important;
      font-weight: 700 !important;
      width: 33%;
      color: #ccc;
    }

    od-tabs > nav > a.active {
      color: $primary;
    }
  }
}

ow-sidebar-location {
  display: block;
  padding: $gutter;
}

ow-sidebar-event {
  display: block;
  padding: $gutter;
}

ow-sidebar-data {
  display: block;
  padding-top: 10px;

  .ow-sidebar-data__boolean {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 1px solid $primary;

    &__active {
      background: $primary;
    }
  }

  .ow-sidebar-data__header {
    background: $primary;
    color: white;
    font-size: 14px;
  }

  table {
    font-size: 12px;

    tbody {
      tr {
        th {
          font-weight: bold;
          text-align: left;
        }

        td {
        }

        th,
        td {
          padding: 5px;
          border-bottom: 1px solid silver;
          height: 20px;
          text-align: center;

          &:first-of-type {
            padding-left: 10px;
            text-align: left;
          }

          &:last-of-type {
            padding-right: 10px;
          }
        }
      }
    }
  }
}
