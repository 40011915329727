opendash-reporting-create {
  display: block;
  height: auto;
  width: 100%;

  padding: $gutter;
}

.od-select-report__item {
  @extend .clearfix;

  background: $od-widget-background-color;
  border-bottom-width: 1px;
  border-style: solid;
  border-color: $od-widget-border-color;
  border: 1px solid $od-widget-border-color;
  padding: $gutter/2;
  .name {
    height: 50px;
    padding: 0 16px;
    overflow: hidden;
  }
  .icon {
    float: right;
    height: 50px;
    width: 40px;
    padding: 10px 8px;
    text-align: right;
    font-size: 1.2em;
  }
}

.opendash-reporting-create {
  hr {
    border: 0;
    border-bottom: 2px solid $od-widget-border-color;
    margin-bottom: $gutter;
  }

  &__settings {
    &__item {
      @extend .clearfix;

      background: $od-widget-background-color;
      border-width: 1px;
      border-style: solid;
      border-color: $od-widget-border-color;

      border-bottom-width: 0;

      padding: $gutter/2;

      &__name {
        float: left;
        line-height: 50px;
      }

      &__options {
        float: right;

        line-height: 50px;

        padding: 0 10px;

        border-left: 1px solid $od-widget-border-color;

        fieldset,
        input,
        select,
        textarea {
          margin-bottom: 0;
        }

        select {
          width: auto;
          border: 0;
        }
      }

      &:nth-last-child(1) {
        border-bottom-width: 1px;
      }
    }
  }
}
