.opendash-widget-table {
  background: white;
  padding: 10px;

  height: calc(100% - 55px);
  overflow: auto;

  &__bool {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    border: 1px solid #0063ac;

    &__active {
      background: #0063ac;
    }
  }

  &__row {
    &:first-child {
      font-weight: bold;
      border-bottom: 2px solid rgba(0, 0, 0, 0.1);
      // margin-bottom: 10px;
      padding: 5px 10px;
    }

    &:nth-child(2n) {
      background: rgba(0, 0, 0, 0.03);
    }
    padding: 2px 10px;
    display: flex;
    flex-direction: row;
  }

  &__col {
    width: 100%;
    line-height: 30px;
    overflow: hidden;

    i {
      color: rgba(0, 0, 0, 0.5);
      margin-left: 5px;
    }
  }
}
