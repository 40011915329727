p {
  margin-top: 0;
}

a,
a:hover {
  text-decoration: none;
}

[ng-click] {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-light;
  margin-bottom: 2rem;
  margin-top: 0;
}

h1 {
  font-size: 4rem;
  letter-spacing: -0.1rem;
  line-height: 1.2;
}

h2 {
  font-size: 3.6rem;
  letter-spacing: -0.1rem;
  line-height: 1.25;
}

h3 {
  font-size: 3rem;
  letter-spacing: -0.1rem;
  line-height: 1.3;
}

h4 {
  font-size: 2.4rem;
  letter-spacing: -0.08rem;
  line-height: 1.35;
}

h5 {
  font-size: 1.8rem;
  letter-spacing: -0.05rem;
  line-height: 1.5;
}

h6 {
  font-size: 1.6rem;
  letter-spacing: 0;
  line-height: 1.4;
}
