// Form
// ––––––––––––––––––––––––––––––––––––––––––––––––––
input[type="email"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="url"],
textarea,
select {
  // appearance: none;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid $od-color-border;
  box-shadow: none;
  box-sizing: inherit;
  outline: none;
  height: 40px;
  padding: 6px 10px;
  width: 100%;

  &:focus {
    border-color: $primary;
    outline: 0;
  }
}

select {
  // background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="#d1d1d1" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>') center right no-repeat;
  padding-right: 3rem;
  // &:focus {
  //     background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 29 14" width="29"><path fill="#9b4dca" d="M9.37727 3.625l5.08154 6.93523L19.54036 3.625"/></svg>');
  // }
}

textarea {
  min-height: 6.5rem;
}

label,
legend {
  display: block;
  font-size: 1.6rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

fieldset {
  border-width: 0;
  padding: 0;
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
}

.label-inline {
  display: inline-block;
  font-weight: normal;
  margin-left: 0.5rem;
}

fieldset,
input,
select,
textarea {
  margin-bottom: 1.5rem;
}
