.app__vsensor {
  &__remove {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        @extend .raised;

        background: $white;
        color: $white-font;

        list-style: none;
        margin: 0;
        padding: $gutter/2;

        height: 30px + $gutter;
        line-height: 30px;

        i.fa {
          float: right;
          height: 30px;
          width: 30px;
          line-height: 30px;
          text-align: center;
        }

        &:hover {
          background: $white-hover;
          color: $white-hover-font;
        }

        &:not(:last-child) {
          border-bottom: none;
        }
      }
    }
  }
}
