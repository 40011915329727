ow-frontpage {
  display: block;
  width: 100%;

  @include media(">1200px") {
    margin-top: -80px;

    height: 100vh;
  }
}

.ow-frontpage {
  $tile-width: 200px;
  $tile-padding: 10px;

  width: 100%;

  @include media(">1200px") {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    // background: linear-gradient(to bottom, rgba(180, 180, 180, 1) 0%, rgba(180, 180, 180, 0.5) 25%, rgba(255, 255, 255, 0) 50%, rgba(180, 180, 180, 0.41) 75%, rgba(180, 180, 180, 0.81) 100%);
  }

  &__tile {
    text-align: center;

    margin: $tile-padding auto;

    @include media(">1200px") {
      margin: $tile-padding;
      flex-direction: column;
    }

    display: flex;
    justify-content: center;
    align-items: center;

    width: $tile-width;
    height: $tile-width;

    color: white;
    background-color: #4387c7;
    border: 3px solid #4387c7;

    &:hover {
      background-color: white !important;
      color: #555;
    }

    &.opendash {
      background-color: #4387c7;
      border: 3px solid #4387c7;

      &:hover {
        color: #4387c7;
      }
    }

    &.openanal {
      background-color: #80bb47;
      border: 3px solid #80bb47;

      &:hover {
        color: #80bb47;
      }
    }

    &.openuser {
      background-color: #b4b4b4;
      border: 3px solid #b4b4b4;

      &:hover {
        color: #b4b4b4;
      }
    }

    &.opensensor {
      background-color: #d5d92f;
      border: 3px solid #d5d92f;

      &:hover {
        color: #d5d92f;
      }
    }

    &.openadmin {
      background-color: #676767;
      border: 3px solid #676767;

      &:hover {
        color: #676767;
      }
    }

    &--hidden-on-mobile {
      @include media("<=1200px") {
        display: none;
      }
    }

    &__icon {
      display: block;
      width: 100%;
      margin-bottom: 20px;
    }

    &__label {
      display: block;
      width: 100%;
    }
  }

  &__qrcode {
    display: none;

    margin: 0 auto;

    width: 200px;
    height: 200px;

    @media screen and (max-height: 750px) {
      display: none !important;
    }

    @include media(">1200px") {
      display: block;
      margin: 0;
      position: absolute;
      bottom: 50px;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}
