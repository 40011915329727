.od-itemlist{
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 30px;
   
}
.od-itemlist li{
    border: 1px solid #a9a9a9;
    border-bottom: none;
    background: #fff;
    color: #a9a9a9;
    list-style: none;
    margin: 0;
    padding-left: 10px;
    height: 50px;
    &:hover{
        border: 1px solid #3374cc;
        background: rgba(0,0,0,.01)
    }
}
.od-itemlist li.last{
    border: 1px solid #a9a9a9;
    background: #fff;
    color: #a9a9a9;
    list-style: none;
    margin: 0;
    padding-left: 10px;
    height: 50px;
    &:hover{
        border: 1px solid #3374cc;
        background: rgba(0,0,0,.01)
    }
}
.od-sensor-filter-bar input{
    display: inline-block;
}
.od-sensor-filter-bar .od-sensor-filter-textfilter{
    height:30px;
    width:80%;
    margin-bottom: 0px;

}
.od-filter-flaglist{
    display: inline-block;
    width: 18%
}
.od-filter-flaglist i{
    text-align: center;
    padding-left: 1%;
    width: 45%;
    
}
.od-itemlist-itemName{
    color: #444;
    display: block;
}
.od-itemlist-itemDimension{
    color: #999;
    font-style: italic;
    
}