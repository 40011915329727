$od-sidebar-background: #f4f4f4 !default;
$od-sidebar-background-active: #fff !default;

$od-sidebar-text: #555 !default;
$od-sidebar-text-muted: $primary !default;

$od-sidebar-item-height: 50px !default;

od-sidebar,
.od-sidebar {
  font-family: "Raleway", sans-serif;

  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: auto;
  height: 100vh;
  width: 100%;
  max-width: 350px;
  background: $od-sidebar-background;
  z-index: 5010;
  overflow-y: auto;
  color: $od-sidebar-text;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;

  &.active {
    transform: translateX(0) !important;
  }

  a {
    color: $od-sidebar-text;
    text-decoration: none;
  }

  main {
    padding-top: 75px;
    flex: 1;
  }

  footer {
    a {
      display: block;
      padding: 0 $gutter;
      line-height: $od-sidebar-item-height;
      i {
        margin-right: $gutter;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.2);
      }
    }
  }
  section {
    padding: 10px 0;
    header {
      font-size: 0.8em;
      font-weight: bold;
      text-transform: uppercase;
      color: $od-sidebar-text-muted;
      padding: 5px $gutter;
    }
    a {
      display: block;
      padding: 0 $gutter;
      line-height: $od-sidebar-item-height;

      .badge {
        // display: inline-block;
        float: right;
        border: 1px solid rgba(0, 0, 0, 0.1);
        background: rgba(0, 0, 0, 0.1);
        height: 22px;
        margin-top: 15px;
        line-height: 20px;
        padding: 0 3px;
        border-radius: 3px;
        margin-left: 3px;
        text-transform: uppercase;
        font-size: 0.5em;
        letter-spacing: 2px;
        font-weight: 700;
      }

      &:hover {
        background: $od-sidebar-background-active;
      }
    }
  }

  section header,
  section footer {
    font-size: 16px;
    margin-bottom: 15px;
    text-transform: none;
  }

  section a,
  footer a {
    border-bottom: 1px solid #ddd;

    &:first-of-type {
      border-top: 1px solid #ddd;
    }
  }
}
